import {IUser} from "../Models/IUser";
import $api from "../http";
import {AxiosResponse} from "axios";
import {ICustomer} from "../Models/ICustomer";
import {ICompanyData, User} from "../Models/ICompanyData";

export default class CustomerService{
    static async createCustomers(data:any):Promise<AxiosResponse<ICustomer>>{
        return $api.post('/admins/company/create',data);
    }
    static async fetchCustomers():Promise<AxiosResponse<ICustomer[]>>{
        return $api.get('/admins/company');
    }
    static async findCompany(id:string):Promise<AxiosResponse<ICompanyData>>{
        return $api.get(`/admins/company-find/${id}`);
    }
    static async addUser(name:string,phone:string,email:string,companyId:string):Promise<AxiosResponse<User>>{
        return $api.post(`/admins/user/add?companyId=${companyId}`,{"name":name,email:email,phone:phone});
    }
    static async sendPush(title:string,message:string,companies:string[]):Promise<AxiosResponse>{
        return $api.post(`/notification/send-push`,{"title":title,message:message,companies:companies});
    }
}