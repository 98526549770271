import React from 'react';
import {BrowserRouter} from "react-router-dom";
//import {LocalizationProvider} from "@mui/x-date-pickers";
//import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
//import {ru} from "date-fns/locale";
import AppRouter from "./Components/AppRouter";

function App() {
  return (
      <BrowserRouter>
          <AppRouter />
      </BrowserRouter>
  );
}

export default App;
