import $api from "../http";
import {IProduct} from "../Models/IProduct";
import {AxiosResponse} from "axios";
import {ICategory} from "../Models/ICategory";
import {NewProductInputs} from "../Models/dto/NewProductInputs";
import {ProductBlockDto} from "../Models/dto/ProductBlockDto";

export default class ProductService{
    static async fetchProducts():Promise<AxiosResponse<IProduct[]>>{
        return $api.get('/products/products-for-admin');
    }
    static async fetchCategories():Promise<AxiosResponse<ICategory[]>>{
        return $api.get('/products/fetchCategory');
    }
    static async enableProduct(id:number):Promise<AxiosResponse<IProduct>>{
        return $api.post(`/products/enable-product/${id}`);
    }

    static async createProduct(
        data:NewProductInputs,
        file:File,
        animalType:string,
        typeProduct:string,
        blocks:ProductBlockDto[],
        category:ICategory[]
    ):Promise<AxiosResponse<IProduct>>{
        let formData = new FormData();

        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("descriptionShort", data.descriptionShort);
        formData.append("position", '1');
        formData.append("animalType",animalType);
        formData.append("avatar", file);
        formData.append("type", typeProduct);
        formData.append("maxConcentration", data.maxConcentration.toString());
        formData.append("minConcentration", data.minConcentration.toString());
        formData.append("gamma", data.gamma);
        formData.append("recommendedConcentration", data.recommendedConcentration.toString());
        formData.append("price", data.price.toString());
        formData.append("exchangeEnergy", data.exchangeEnergy.toString());


        //Элементы
        formData.append("elements[0][value]", data.protein.toString());
        formData.append("elements[0][name]", 'proteins');

        formData.append("elements[1][value]", data.fats.toString());
        formData.append("elements[1][name]", 'fats');

        formData.append("elements[2][value]", data.lactose.toString());
        formData.append("elements[2][name]", 'lactose');

        formData.append("elements[3][value]", data.cellulose.toString());
        formData.append("elements[3][name]", 'fiber');

        blocks.forEach((b,i)=>{
            formData.append(`blocksList[${i}][type]`, b.type);
            formData.append(`blocksList[${i}][title]`, b.title);
            formData.append(`blocksList[${i}][message]`, b.message!);

        })
        category.forEach((b,i)=>{
            formData.append(`categories[${i}]`, b.id.toString());
        })
        return $api.post('/products/create',formData,{
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    }
}