import {
    Autocomplete, Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, Grid,
    InputLabel, ListItem, MenuItem,
    Select, Stack,
    TextField, Typography
} from '@mui/material';
import React, {FC, useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import Errorhandler from "../../errorHendler";
import CustomerService from "../../Service/CustomerService";
import 'react-dadata/dist/react-dadata.css';
import {NewUserDto} from "../../Models/dto/new_user_dto";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {CompanySuggest} from "../../Models/suggest/CompanySuggest";
import SuggestService from "../../Service/SuggestService";
import {ICustomer} from "../../Models/ICustomer";



interface NewCustomerInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:ICustomer )=>void

}
type Inputs = {
    mainEmail: string,
    mainPhone: string,
};

const NewCustomer:FC<NewCustomerInterface> = (props) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();

    const [users,setUsers]=useState<NewUserDto[]>([{name:'',email:'',phone:''}]);
    const [selectCompany,setCompany]=useState<null|CompanySuggest>()
    const [queryCompany,setQueryCompany]=useState('')
    const [options, setOptions] = React.useState<readonly CompanySuggest[]>([]);

    const onSubmit: SubmitHandler<Inputs> =async (data) => {

        if(selectCompany==null){
            alert('Укажите компанию из списка')
            return;
        }

        if(users.filter(u=>u.email===''||u.name===''||u.phone==='').length>0){
            alert('Заполните все поля у сотрудников')
            return;
        }

        try{
            const customer=await CustomerService.createCustomers(
                {
                    "name":selectCompany.value,
                    "inn":selectCompany.inn,
                    "mainEmail":data.mainEmail,
                    "mainPhone":data.mainPhone,
                    "daysDeadLine":30,
                    "users":users
                }
            )
            props.handleCreate(customer.data)
        }catch (e){
            alert(JSON.stringify(e))
            alert(Errorhandler.errorMessage(e))
        }
    };


    useEffect(()=>{
        SuggestService.suggestParty(queryCompany).then(e=>{
            setOptions(e.data)
        })
    },[queryCompany])

    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} >
            <DialogTitle>Новая компания</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText>
                    Добавить новую компанию
                </DialogContentText>
                <div style={{height:10}}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Autocomplete
                     options={options}
                     value={selectCompany}
                     onChange={(event,newValue: CompanySuggest | null)=>setCompany(newValue)}
                     renderInput={(params) => (
                         <TextField {...params} label="Название или ИНН" fullWidth autoFocus/>
                     )}
                     onInputChange={(event, newInputValue) => {
                         setQueryCompany(newInputValue);
                     }}
                     getOptionLabel={(option) =>
                         typeof option === 'string' ? option : option.value
                     }
                     renderOption={(props, option)=>{
                         return (
                             <li {...props}>
                                 <Stack spacing={1}>
                                     <Typography variant="body2">
                                         {option.value}
                                     </Typography>
                                     <Typography variant="caption" color="text.secondary">
                                         ИНН: {option.inn} Статус: {option.state}
                                     </Typography>
                                 </Stack>


                             </li>
                         );
                     }
                     }
                    />
                    <TextField

                        margin="dense"
                        {...register("mainEmail",{ required: true})}
                        label="Email компании"
                        type="email"
                        fullWidth
                        variant="outlined"
                    />

                    <TextField
                        margin="dense"
                        {...register("mainPhone",{ required: true})}
                        label="Телефон компании"
                        type="number"
                        fullWidth
                        variant="outlined"
                    />
                    <div style={{height:10}}/>
                    <Button
                        onClick={()=>setUsers(prevState => [...prevState,{name:'',email:'',phone:''}])}
                        fullWidth
                        variant="outlined">
                        Добавить сотрудника
                    </Button>
                    <div style={{height:10}}/>
                    <Stack spacing={1}>
                        {
                            users.map(
                                (u,index)=><Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Имя"
                                            variant="outlined"
                                            onChange={(e)=>users[index].name=e.target.value}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Телефон"
                                            variant="outlined"
                                            onChange={(e)=>users[index].phone=e.target.value}

                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Почта"
                                            variant="outlined"
                                            onChange={(e)=>users[index].email=e.target.value}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete"
                                                    onClick={() => setUsers(prevState => prevState.filter(item => item !== users[index]))}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Stack>
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Отмена</Button>
                <Button onClick={handleSubmit(onSubmit)}>Создать компанию</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewCustomer;