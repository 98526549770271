import {makeAutoObservable} from "mobx";
import AuthService from "../Service/AuthService";
import Errorhandler from "../errorHendler";
import {IUser} from "../Models/IUser";


export default class UserStore{
    isAuth = false;
    user = {} as IUser;

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }
    setUser(user: IUser) {
        this.user = user;
    }
    constructor() {
        makeAutoObservable(this);
    }

    async login(data:any){
        console.log(JSON.stringify(data))
        try{
            const res=await AuthService.login(data);
            localStorage.setItem('token',res.data.token);
            this.setAuth(true);
            this.setUser(res.data);
            return true;
        }catch (e){
            console.log(JSON.stringify(e))
            alert(Errorhandler.errorMessage(e))
            throw new Error()
        }

    }
}