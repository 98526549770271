import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {IProduct} from "../../Models/IProduct";
import {SubmitHandler, useForm} from "react-hook-form";
import {NewProductInputs} from "../../Models/dto/NewProductInputs";
import ProductService from "../../Service/ProductService";
import Errorhandler from "../../errorHendler";
import CustomerService from "../../Service/CustomerService";
import {User} from "../../Models/ICompanyData";
import {useSearchParams} from "react-router-dom";


interface AddUserInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:User )=>void
}

export type AddUserInputs = {
    name: string,
    email: string,
    phone: string,

};


const AddUser:FC<AddUserInterface> = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control } = useForm<AddUserInputs>();
    const [searchParams, setSearchParams] = useSearchParams();

    const onSubmit: SubmitHandler<AddUserInputs> =async (data) => {

        try{
            const product=await CustomerService.addUser(data.name,data.phone,data.email,searchParams.get('id')??'')
            props.handleCreate(product.data)
        }catch (e){
            alert(JSON.stringify(e))
            alert(Errorhandler.errorMessage(e))
        }
    };




    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Новый сотрудник</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        autoFocus
                        margin="dense"
                        {...register("name",{ required: true,})}
                        label="Название"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        {...register("email",{ required: true,})}
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        {...register("phone",{ required: true,})}
                        label="Телефон"
                        type="text"
                        fullWidth
                        variant="outlined"
                        error={(errors.phone!==undefined)}
                    />
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Отмена</Button>
                <Button onClick={handleSubmit(onSubmit)}>Добавить пользователя</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUser;