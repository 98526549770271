import React from 'react';
import MainComponent from "../Components/MainComponent";
import TitleRow from "../Components/Title";
import {Paper} from "@mui/material";

const CreateProduct = () => {
    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Новый продукт`}/>
            </React.Fragment>
            <Paper sx={{mt:2,p:2}}>
                <form>

                </form>
            </Paper>
        </MainComponent>
    );
};

export default CreateProduct;