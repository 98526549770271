import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Box, Grid} from "@mui/material";

interface TitleProps {
    title?: React.ReactNode;
    actions?:React.ReactNode
}

export default function TitleRow(props: TitleProps) {
    return (
        <div style={{ width: '100%' }}>
            <Box display="flex" p={1} bgcolor="background.paper">
                <Box p={1} width="100%">
                    <Grid item xs={8}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            {props.title}
                        </Typography>
                    </Grid>
                </Box>

                <Box p={1} flexShrink={0}>
                    {
                        props.actions
                    }
                </Box>
            </Box>
        </div>


    );
}