import React, {FC} from 'react';
import {Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {AddUserInputs} from "./AddUser";
import {User} from "../../Models/ICompanyData";
import CustomerService from "../../Service/CustomerService";
import Errorhandler from "../../errorHendler";
import {ICustomer} from "../../Models/ICustomer";



interface PushInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:User )=>void
    companies:ICustomer[]
}

export type PushInputs = {
    title: string,
    message: string,

};

const SendPushDialog:FC<PushInterface> = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control,reset } = useForm<PushInputs>();
    const [value, setValue] = React.useState<ICustomer[]>([]);

    const onSubmit: SubmitHandler<PushInputs> =async (data) => {

        try{
            const push=await CustomerService.sendPush(data.title,data.message,value.map(e=>e.id))
            props.handleClose()
            reset();
        }catch (e){
            alert(JSON.stringify(e))
            alert(Errorhandler.errorMessage(e))
        }
    };
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Отправить уведомление</DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <Autocomplete
                        multiple

                        value={value}
                        onChange={(event, newValue) => {
                            setValue([...newValue]);
                        }}
                        options={props.companies}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        fullWidth
                        renderInput={(params) => (
                            <TextField {...params} label="Компании" placeholder="Компании" margin="dense"/>
                        )}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        {...register("title",{ required: true,})}
                        label="Название"
                        type="text"
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        {...register("message",{ required: true,})}
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                    />

                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Отмена</Button>
                <Button onClick={handleSubmit(onSubmit)}>Отправить</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendPushDialog;