import React, {FC, useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Errorhandler from "../../errorHendler";
import {
    Autocomplete, Box,
    Button, Chip, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {ICategory} from "../../Models/ICategory";
import ProductService from "../../Service/ProductService";
import {ProductBlockDto} from "../../Models/dto/ProductBlockDto";
import { NewProductInputs } from '../../Models/dto/NewProductInputs';
import Dropzone from "react-dropzone";
import {IProduct} from "../../Models/IProduct";


interface NewProductInterface {
    open:boolean
    handleClose:()=>void
    handleCreate:(customer:IProduct )=>void
}


const NewProduct:FC<NewProductInterface> = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control,reset } = useForm<NewProductInputs>();
    const [value, setValue] = React.useState<ICategory[]>([]);
    const [blocks, setBlocks] = React.useState<ProductBlockDto[]>([
        {title:'Ингридиенты',type:'message',list:[],message:''} as ProductBlockDto,
        {title:'Выпойка из ведра',type:'message',list:[],message:'Налить в ведро необходимое количество воды температурой 50-55°C. Добавить взвешенный объем ЗЦМ и мешать в течение 30 секунд. Вскармливать ЗЦМ при температуре 42-45°C и всегда придерживаться одного и того же уровня температуры.'} as ProductBlockDto,
        {title:'Выпойка из станции',type:'message',list:[],message:'Отрегулировать температуру автоматической выпаивательной станции на 45°C. Регулярно проверять температуру и концентрацию. Регулярно чистить резервуар, трубки и соски.'} as ProductBlockDto,
        {title:'Срок годности и условия хранения',type:'message',list:[],message:'12 месяцев с даты производства товара. Дату производства смотреть на упаковке.'} as ProductBlockDto,
    ]);
    const [categoriesList, setCategoriesList] = React.useState<ICategory[]>([]);
    const [type,setType]=useState('')
    const [typeProduct,setTypeProduct]=useState('')


    const onSubmit: SubmitHandler<NewProductInputs> =async (data) => {

        try{
            if(data.file[0]==null){
                alert('Укажите изображение')
                return;
            }
            if(typeProduct===''){
                alert('Укажите Тип продукта')
                return;
            }
            if(type===''){
                alert('Укажите тип животного')
                return;
            }
            const product=await ProductService.createProduct(data,data.file[0],type,typeProduct,blocks,value)
            props.handleCreate(product.data)
            reset()
            setBlocks([
                {title:'Ингридиенты',type:'message',list:[],message:''} as ProductBlockDto,
                {title:'Выпойка из ведра',type:'message',list:[],message:'Налить в ведро необходимое количество воды температурой 50-55°C. Добавить взвешенный объем ЗЦМ и мешать в течение 30 секунд. Вскармливать ЗЦМ при температуре 42-45°C и всегда придерживаться одного и того же уровня температуры.'} as ProductBlockDto,
                {title:'Выпойка из станции',type:'message',list:[],message:'Отрегулировать температуру автоматической выпаивательной станции на 45°C. Регулярно проверять температуру и концентрацию. Регулярно чистить резервуар, трубки и соски.'} as ProductBlockDto,
                {title:'Срок годности и условия хранения',type:'message',list:[],message:'12 месяцев с даты производства товара. Дату производства смотреть на упаковке.'} as ProductBlockDto,
            ])

        }catch (e){
            alert(JSON.stringify(e))
            alert(Errorhandler.errorMessage(e))
        }
    };


    useEffect(()=>{
        loadingCategories().then();
    },[])

    const loadingCategories=async ()=>{
        try{
            const list=await ProductService.fetchCategories();
            if (list.data.length>0){
                setCategoriesList(prevState => [...prevState,...list.data])
            }
        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} maxWidth={'xl'}>
            <DialogTitle>Новый продукт</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText>
                    Добавить новый продукт
                </DialogContentText>
                <div style={{height:10}}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                autoFocus
                                margin="dense"
                                {...register("name",{ required: true,})}
                                label="Название"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                            <Autocomplete
                                multiple

                                value={value}
                                onChange={(event, newValue) => {
                                    setValue([...newValue]);
                                }}
                                options={categoriesList}
                                getOptionLabel={(option) => option.name}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Категории" placeholder="Категория" margin="dense"/>
                                )}
                            />
                            <TextField
                                margin="dense"
                                {...register("descriptionShort",{ required: true})}
                                label="Короткое описание"
                                type="text"
                                fullWidth
                                variant="outlined"
                                rows={3}
                                minRows={2}
                            />
                            <TextField
                                margin="dense"
                                {...register("description",{ required: true})}
                                label="Описание"
                                type="text"
                                fullWidth
                                variant="outlined"
                                minRows={4}
                            />
                            <Stack direction="row" spacing={1} alignItems="center">
                                <TextField
                                    margin="dense"
                                    {...register("minConcentration",{ required: true})}
                                    label="Минимальная концентрация"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                />
                                <TextField
                                    margin="dense"
                                    {...register("recommendedConcentration",{ required: true})}
                                    label="Рекомендуемая концентрация"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                />
                                <TextField
                                    margin="dense"
                                    {...register("maxConcentration",{ required: true})}
                                    label="Максимальная концентрация"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                />
                            </Stack>
                            <FormControl fullWidth  margin="dense">
                                <InputLabel id="demo-simple-select-label">Тип животного</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Тип животных"
                                    value={type}
                                    onChange={(event)=>setType(event.target.value as string)}
                                >
                                    <MenuItem value={'calf'}>Теленок</MenuItem>
                                    <MenuItem value={'goatling'}>Козлёнок</MenuItem>
                                    <MenuItem value={'piglet'}>Поросенок</MenuItem>
                                    <MenuItem value={'lamb'}>Ягненок</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth                                 margin="dense"
                            >
                                <InputLabel id="demo-simple-select-label">Тип продукта</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Тип продукта"
                                    value={typeProduct}
                                    onChange={(event)=>setTypeProduct(event.target.value as string)}
                                >
                                    <MenuItem value={'ЗЦМ'}>ЗЦМ</MenuItem>
                                    <MenuItem value={'Комбикорм'}>Комбикорм</MenuItem>
                                    <MenuItem value={'Активная добавка'}>Активная добавка</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                margin="dense"
                                {...register("protein",{ required: true})}
                                label="Сырой протеин"
                                type="number"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                {...register("fats",{ required: true})}
                                label="Сырые жиры"
                                type="number"
                                fullWidth
                                variant="outlined"
                            />

                            <TextField
                                margin="dense"
                                {...register("lactose",{ required: true})}
                                label="Лактоза"
                                type="number"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                {...register("cellulose",{ required: true})}
                                label="Сырая клетчатка"
                                type="number"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                {...register("exchangeEnergy",{ required: true})}
                                label="Обменная энергия"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                {...register("gamma",{ required: true})}
                                label="Гамма"
                                fullWidth
                                variant="outlined"
                            />
                            <TextField
                                margin="dense"
                                {...register("price",{ required: true})}
                                label="Цена"
                                fullWidth
                                variant="outlined"
                            />

                            <Controller
                                render={({field:{onChange,onBlur,value}}) => (
                                    <>
                                        <Dropzone
                                            onDrop={onChange}
                                            maxFiles={1}
                                        >
                                            {
                                                ({getRootProps,getInputProps})=>(
                                                    <Paper {...getRootProps()} variant={'outlined'} >
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            minHeight="10vh"
                                                            sx={{
                                                                p:4
                                                            }}>
                                                            <Typography  style={{textAlign:'center'}}>Загрузите с компьютера или перетащите файлы для отправки</Typography>
                                                        </Box>
                                                        <input {...getInputProps()} name={'file'} hidden={true} onBlur={onBlur}/>
                                                    </Paper>
                                                )
                                            }
                                        </Dropzone>
                                        {
                                            value.map((element,index)=><p key={index}>Фото:{element.name}</p>)
                                        }
                                    </>
                                )}
                                name={'file'}
                                control={control}
                                defaultValue={[]}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={()=>setBlocks(prevState => [...prevState,{title:'',type:'message',list:[]}])}
                                fullWidth
                                variant="outlined">
                                Добавить Блок
                            </Button>
                            <div style={{height:10}}/>
                            {
                                blocks.map((block,index)=>{
                                    return (
                                        <div key={`block_index_${index}`}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={6}>
                                                    <TextField
                                                        margin="dense"
                                                        label="Заголовок"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={blocks[index].title}
                                                        onChange={(e)=>blocks[index].title=e.target.value}

                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {
                                                        /*
                                                        <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            label="Тип"
                                                            onChange={(event) => setBlocks(prevState => prevState.filter(e => (e === blocks[index]) ? e : {
                                                                ...blocks[index],
                                                                type:event.target.value
                                                            }))}
                                                        >
                                                            <MenuItem value={'message'}>Строка</MenuItem>
                                                            <MenuItem value={'list'}>Список</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                         */
                                                    }
                                                </Grid>

                                            </Grid>
                                            <TextField
                                                margin="dense"
                                                label="Сообщение"
                                                type="text"
                                                value={blocks[index].message}
                                                onChange={(e)=>blocks[index].message=e.target.value}
                                                fullWidth
                                                variant="outlined"
                                            />

                                        </div>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>

                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Отмена</Button>
                <Button onClick={handleSubmit(onSubmit)}>Создать продукт</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewProduct;