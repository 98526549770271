import React, {useEffect, useState} from 'react';
import MainComponent from "../Components/MainComponent";
import {Button, ListItem, Paper, Stack, TextField} from "@mui/material";
import TitleRow from "../Components/Title";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import {RouteNames} from "../Routes";
import Errorhandler from "../errorHendler";
import CustomerService from "../Service/CustomerService";
import {ICustomer} from "../Models/ICustomer";
import NewCustomer from "../Components/Dialogs/NewCustomer";
import SendPushDialog from "../Components/Dialogs/SendPushDialog";

const CustomersPage = () => {

    const [customers,setCustomers]=useState<ICustomer[]>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openPush, setOpenPush] = React.useState(false);

    const history=useNavigate();


    const[page,setPage]=useState(0)
    const[openForm,setOpenForm]=useState(false)

    useEffect(()=>{
        loadingCustomers().then();
    },[])

    const loadingCustomers=async ()=>{
        try{
            const list=await CustomerService.fetchCustomers();
            if (list.data.length>0){
                setPage(prevState => prevState++)
                setCustomers(prevState => [...prevState,...list.data])
            }
        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }
    }


    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Клиенты`} actions={
                    <Stack direction={"row"}>
                        <Button variant="contained" onClick={()=>setOpenDialog(true)}>Новый Клиент</Button>
                        <Button variant="contained" onClick={()=>setOpenPush(true)}>Рассылка</Button>
                    </Stack>
                }/>
            </React.Fragment>
            <Paper sx={{mt:2,p:2}}>
                <TextField
                    id="outlined-basic"
                    label="Поиск"
                    variant="outlined"
                />
                <List>
                    {
                        customers.map((c,index)=>
                            <ListItem key={index}>
                                <ListItemText
                                    primary={(c.name==='')?'Не указан':c.name}
                                    secondary={c.inn}
                                    onClick={()=>{
                                        history(RouteNames.CUSTOMER_DETAIL+`?id=${c.id}`)
                                    }
                                    }
                                />
                            </ListItem>
                        )
                    }

                </List>
            </Paper>
            <NewCustomer
                handleClose={()=>setOpenDialog(false)}
                handleCreate={(customer)=>{
                    setCustomers(prevState => [...prevState,customer])
                    setOpenDialog(false);
                }}
                open={openDialog}
            />
            <SendPushDialog
                companies={customers}
             handleClose={()=>setOpenPush(false)}
             handleCreate={()=>setOpenPush(false)}
             open={openPush}/>
        </MainComponent>
    );
};

export default CustomersPage;