import {JSXElementConstructor} from "react";
import AuthPage from "../Pages/AuthPage";
import CustomersPage from "../Pages/CustomersPage";
import CustomerDetail from "../Pages/CustomerDetail";
import ProductList from "../Pages/ProductList";
import CreateProduct from "../Pages/CreateProduct";



export interface IRoute{
    path:string
    component:JSXElementConstructor<any|string>
    exact?:boolean
    index?:boolean
}
export enum RouteNames{
    LOGIN='/',
    DASHBOARD='/dashboard',
    CUSTOMERS='/customers',
    PRODUCTS='/products',
    NEW_PRODUCTS='/new-products',
    CUSTOMER_DETAIL='/customer',
    SETTINGS='/settings',
    NEW_LETTERS='/new_email',
    LETTERS='/letters',
    USERS='/users',

}

export const publicRoutes:IRoute[]=[
    {path: RouteNames.LOGIN,exact:true,component: AuthPage,index:true},
]
export const privateRoutes:IRoute[]=[
    {path: RouteNames.CUSTOMERS,exact:true,component: CustomersPage,index:true},
    {path: RouteNames.CUSTOMER_DETAIL,exact:true,component: CustomerDetail},
    {path: RouteNames.PRODUCTS,exact:true,component: ProductList},
    {path: RouteNames.LOGIN,exact:true,component: AuthPage},
    {path: RouteNames.NEW_PRODUCTS,exact:true,component: CreateProduct},
]