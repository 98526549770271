import React, {useEffect, useState} from 'react';
import TitleRow from "../Components/Title";
import {Button, Grid, List, ListItem, ListItemButton, Paper, Stack, TextField, Typography} from "@mui/material";
import MainComponent from "../Components/MainComponent";
import {useSearchParams} from "react-router-dom";
import {ICompanyData, User} from "../Models/ICompanyData";
import CustomerService from "../Service/CustomerService";
import Errorhandler from "../errorHendler";
import ListItemText from "@mui/material/ListItemText";
import AddUser from "../Components/Dialogs/AddUser";

const CustomerDetail = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [company,setCompany]=useState<ICompanyData>()
    const [showAddUser,setShowAddUser]=useState(false)
    const [workers,setWorkers]=useState<User[]>([])

    useEffect(()=>{
        findCompany().then();
    },[searchParams.get('id')])

    const findCompany=async ()=>{
        try{
            const data=await CustomerService.findCompany(searchParams.get('id')!);
            setCompany(data.data)
            setWorkers(data.data.users);
        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }
    }


    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Детальная информация о компании `} actions={
                    <Stack direction={"row"}>

                    </Stack>
                }/>
            </React.Fragment>
            <Paper sx={{mt:2,p:2}}>
                {
                    (company!=null)&&<Grid container spacing={2}>
                        <Grid item xs={6}>
                           <Stack>
                               <Typography variant="h6" gutterBottom>
                                   {company.name}
                               </Typography>
                               <Typography variant="caption" gutterBottom>
                                   инн {company.inn}
                               </Typography>
                               <Typography variant="caption" gutterBottom>
                                   Телефон {company.phone}
                               </Typography>
                               <Typography variant="caption" gutterBottom>
                                   email {company.email}
                               </Typography>
                           </Stack>
                            <Typography variant="caption" gutterBottom>
                                Дата подписки: {company.deadLine}
                            </Typography>
                            <Typography variant="caption" gutterBottom>
                                Цена молока : {
                                (company.costMilk.length>0)&&`${
                                    company.costMilk[company.costMilk.length-1].cost}`
                            }
                                {
                                    (company.costMilk.length===0)&&'Нет данных'
                                }
                            </Typography>


                            <Stack
                            direction={'row'}>
                                <Typography variant="h6" gutterBottom>
                                    Сотрудники
                                </Typography>
                                <Button onClick={()=>setShowAddUser(true)}>Добавить сотрудника</Button>
                            </Stack>
                            <List>
                                {
                                    workers.map(
                                        user=>{
                                            return (
                                                <ListItem disablePadding key={user.id}>
                                                    <ListItemButton>
                                                        <ListItemText primary={user.name} secondary={user.email}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        }
                                    )
                                }
                            </List>
                        </Grid>
                        <Grid item xs={6}>

                        </Grid>
                    </Grid>
                }
            </Paper>
            <AddUser handleClose={()=>setShowAddUser(false)}  handleCreate={(user)=>{
                setShowAddUser(false)
                setWorkers(prevState => [...prevState,user])
            }
            } open={showAddUser}/>
        </MainComponent>
    );
};

export default CustomerDetail;