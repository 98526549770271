import React, {useEffect, useState} from 'react';
import TitleRow from "../Components/Title";
import {
    Box,
    Button,
    Checkbox,
    ListItem,
    Paper,
    Stack, Table, TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {RouteNames} from "../Routes";
import NewCustomer from "../Components/Dialogs/NewCustomer";
import MainComponent from "../Components/MainComponent";
import {ICustomer} from "../Models/ICustomer";
import {useNavigate} from "react-router-dom";
import CustomerService from "../Service/CustomerService";
import Errorhandler from "../errorHendler";
import ProductService from "../Service/ProductService";
import {IProduct} from "../Models/IProduct";
import NewProduct from "../Components/Dialogs/NewProduct";



interface HeadCell {
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}



const ProductList = () => {

    const [openDialog, setOpenDialog] = React.useState(false);
    const [products,setProducts]=useState<IProduct[]>([]);
    const history=useNavigate();

    useEffect(()=>{
        loadingProducts().then();
    },[])

    const loadingProducts=async ()=>{
        try{
            const list=await ProductService.fetchProducts();
            if (list.data.length>0){
                setProducts(prevState => [...prevState,...list.data])
            }
        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }
    }
    const enableProduct = async (product:IProduct) => {
        try{
            const newProduct=await ProductService.enableProduct(product.id);
            console.log( JSON.stringify(newProduct.data))
            setProducts(prevState => prevState.filter(p=>(p.id===product.id)?newProduct.data:p))
        }catch (e){
            alert(Errorhandler.errorMessage(e))
        }
    }
    return (
        <MainComponent>
            <React.Fragment>
                <TitleRow title={`Продукты`} actions={
                    <Stack direction={"row"}>
                        <Button variant="contained" onClick={()=>history(RouteNames.NEW_PRODUCTS)}>Новый продукт</Button>
                    </Stack>
                }/>
            </React.Fragment>
            <Paper sx={{mt:2,p:2}}>
                <TextField
                    id="outlined-basic"
                    label="Поиск"
                    variant="outlined"
                />

                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                        }}
                                        disabled={true}
                                    />
                                </TableCell>
                                <TableCell
                                    align={'left'}
                                    padding={'normal'}
                                >
                                    Название
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    padding={'normal'}
                                >
                                    Категории
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    padding={'normal'}
                                >
                                    Описание
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    padding={'normal'}
                                >
                                    Просмотры
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    padding={'normal'}
                                >
                                    Элементы
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                products.map((product,index)=>{
                                     const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={`product_row_${product.id}`}
                                            selected={product.active}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={product.active}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    onClick={(e)=>enableProduct(product)}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {product.name}
                                            </TableCell>
                                            <TableCell align="right">{product.categories.map(cat=>cat.name)} {product.active}</TableCell>
                                            <TableCell align="right">{product.descriptionShort}</TableCell>
                                            <TableCell align="right">{product.views}</TableCell>
                                            <TableCell align="right">{product.elements.map(el=><p>{`${el.name}: ${el.elementCount}`}</p>)}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <NewProduct  handleClose={()=>setOpenDialog(false)} handleCreate={
                (product)=>{
                    setProducts(prevState => [...prevState,product])
                    setOpenDialog(false)
                }
            } open={openDialog}/>
        </MainComponent>
    );
};

export default ProductList;